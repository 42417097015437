<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="paymentGeneralKasbon"
          :listGeneralKasbon="listGeneralKasbon"
          :listBank="listBank"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :form="paymentGeneralKasbon" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="updateItem">
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="printJournal">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Journal</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="editItem">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="error" @click.stop="deleteDialog">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="paymentGeneralKasbon.formNumber"
      title="Payment General Kasbon"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailPaymentGeneralKasbon";
import FormEdit from "./FormPaymentGeneralKasbon";
import DialogDelete from "@/components/DialogDelete";

export default {
  name: "payment-general-kasbon-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    listBank: [],
    listGeneralKasbon: [],
    paymentGeneralKasbon: {},
  }),
  methods: {
    async getPaymentGeneralKasbon() {
      await this.$store.dispatch("paymentGeneralKasbon/getById", this.id).then(response => {
        this.paymentGeneralKasbon = response.data;
      });
    },
    async getBanks() {
      await this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBank = response.data;
      });
    },
    printJournal() {
      this.$router.push({ name: "Journal Payment General Kasbon", params: { id: this.id } });
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.checkJournal();
    },
    deleteItem(id) {
      this.$store.dispatch("paymentGeneralKasbon/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getPaymentGeneralKasbon();
      this.edit = true;
    },
    getIntitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getPaymentGeneralKasbon(), this.getBanks()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  created() {
    this.getIntitialData();
  },
};
</script>

<style></style>
